import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { ReactNode } from 'react';
import { palette } from '../../utils/styleguide';

export const NavbarWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: palette.white,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100px',
        paddingRight: {
          sm: 2,
          md: 4,
        },
        paddingLeft: {
          sm: 2,
          md: 4,
        },
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {children}
      </Toolbar>
    </AppBar>
  );
};
