import styled from '@emotion/styled';
import { palette } from '../utils/styleguide';
import { Tabs } from '@mui/material';
import { CustomCurveShapeProps, ShapeDividerProps } from '../utils/types';

export const ShapeDivider = styled.div<ShapeDividerProps>`
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  background-color: ${({ bgColor }) => bgColor || palette.white};

  svg {
    position: relative;
    display: block;
    width: calc(135% + 2.3px);
    height: 120px;
  }

  .shape-fill {
    fill: ${({ shapeColor }) => shapeColor || palette.green};
  }
`;

export const ShapeDividerOp = styled.div<ShapeDividerProps>`
  position: relative;
  top: -110px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);

  svg {
    position: relative;
    display: block;
    width: calc(135% + 1.3px);
    height: 110px;
    transform: rotateY(180deg);
  }

  .shape-fill {
    fill: ${({ shapeColor }) => shapeColor || palette.darkWhite};
  }
`;

export const ParallaxSection = styled.section`
  background-image: url('./laundryParallaxBlurry.jpg');
  background-attachment: fixed;
  background-position: center;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
`;

export const ParallaxLogo = styled.img<{ width: string; height: string }>`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  z-index: 5000;

  &.sticky {
    position: fixed;
    top: 110px;
    left: 50%;
    width: 200px;
    height: 200px;
    z-index: 5000;
    cursor: pointer;
  }
`;

export const StyledCloverFixedLogo = styled.img`
  position: fixed;
  top: 110px;
  left: 50%;
  width: 200px;
  height: 200px;
  z-index: 5000;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

export const StyledTabs = styled(Tabs)`
  border-radius: 20px 20px 0 0;
  .MuiTabs-indicator {
    display: none;
    background-color: ${palette.darkBlue};
  }
  .MuiTab-root {
    background-color: aliceblue;
    color: ${palette.darkBlue};
    font-weight: bold;
  }

  .Mui-selected {
    background-color: ${palette.skyBlue};
    color: ${palette.darkWhite} !important;
  }
`;

export const ParallaxBetweenSections = styled.section`
  position: relative;
  background-image: url('./bubbles.png');
  background-attachment: fixed;
  background-position: center;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  z-index: 1;
`;

export const CustomCurveShapeStyle = styled.div<CustomCurveShapeProps>`
  position: absolute;
  ${(props) => (props.position === 'top' ? 'top: -4px;' : 'bottom: -4px;')}
  left: 0;
  width: 100%;
  overflow: hidden;
  ${(props) => props.rotate && 'transform: rotate(180deg);'}
  z-index: 10;

  svg {
    display: block;
    width: calc(100% + 1.3px);
    height: 50px;
  }

  .shape-fill {
    fill: ${palette.darkWhite};
  }
`;
