import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid2';
import { palette } from '../utils/styleguide';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { FAQ } from '../utils/types';

const FAQTitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography
      id={'services'}
      variant="h4"
      sx={{
        textAlign: 'center',
        paddingBottom: 5,
        fontWeight: 'bold',
        color: palette.darkBlue,
        fontSize: window.innerWidth < 600 ? '2.0rem' : '2.5rem',
      }}
    >
      {children}
    </Typography>
  );
};
const FAQWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid
      id={'faq'}
      container
      justifyContent="center"
      alignItems="center"
      paddingTop={8}
      paddingBottom={10}
      spacing={4}
      direction="column"
      sx={{ backgroundColor: palette.darkWhite }}
    >
      {children}
    </Grid>
  );
};

const AccordionWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid
      container
      direction="column"
      spacing={2}
      sx={{
        marginLeft: { xs: 2, sm: 5, md: 10, lg: 30, xl: 70 },
        marginRight: { xs: 2, sm: 5, md: 10, lg: 30, xl: 70 },
      }}
    >
      {children}
    </Grid>
  );
};

const FAQAccordion = ({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) => {
  return (
    <Grid>
      <Accordion disableGutters square elevation={0}>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          sx={{
            '&.Mui-expanded': {
              backgroundColor: palette.skyBlue,
              color: palette.white,
            },
            '&:hover': {
              backgroundColor: palette.skyBlue,
              color: palette.white,

              '& .MuiSvgIcon-root': {
                color: palette.darkBlue,
              },
              '&:hover .MuiSvgIcon-root': {
                color: palette.white,
              },
            },
          }}
        >
          <Typography>{question}</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: 'flex',
            boxShadow: 'none',
            border: 'none',
            backgroundColor: palette.darkWhite,
          }}
        >
          <Typography>{answer}</Typography>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

const FAQSection = () => {
  const { t } = useTranslation();

  const faq: FAQ = t('faq', { returnObjects: true }) as FAQ;

  return (
    <FAQWrapper>
      <FAQTitle>{t('faq.title')}</FAQTitle>
      <AccordionWrapper>
        {faq.questionsAnswers.map((faqItem, index) => (
          <FAQAccordion
            key={index}
            question={faqItem.question}
            answer={faqItem.answer}
          />
        ))}
      </AccordionWrapper>
    </FAQWrapper>
  );
};

export default FAQSection;
