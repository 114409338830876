import Grid from '@mui/material/Grid2';
import { palette } from '../utils/styleguide';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useScrollToTop } from '../utils/hook';

const RecommendationsTitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography
      id="recommendations"
      variant="h4"
      sx={{
        textAlign: 'center',
        paddingBottom: 5,
        fontWeight: 'bold',
        color: palette.darkBlue,
        backgroundColor: palette.darkWhite,
        fontSize: window.innerWidth < 600 ? '2.0rem' : '2.5rem',
        paddingTop: 28,
      }}
    >
      {children}
    </Typography>
  );
};

const RecommendationsImg = () => {
  return (
    <Grid
      container
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: palette.darkWhite,
      }}
    >
      <Box
        component="img"
        src="./LaundryGuide.jpg"
        alt="Description of image"
        sx={{
          borderRadius: '48px',
          width: {
            xs: '350px',
            sm: '500px',
            md: '900px',
            lg: '1000px',
            xl: '1000px',
          },
          height: {
            xs: '500px',
            sm: '700px',
            md: '1200px',
            lg: '1400px',
            xl: '1400px',
          },
          marginBottom: 8,
        }}
      />
    </Grid>
  );
};

const LaundyRulesTitle = () => {
  return (
    <Typography
      variant="body1"
      sx={{
        textAlign: 'center',
        paddingBottom: 5,
        paddingTop: 5,
        fontWeight: 'bold',
        color: palette.darkBlue,
        fontSize: window.innerWidth < 600 ? '2.0rem' : '2.5rem',
      }}
    >
      {t('laundryRules.title')}
    </Typography>
  );
};

const LaundryRulesContent = () => {
  const RULES = t('laundryRules.rules', {
    returnObjects: true,
  }) as string[];

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          textAlign: 'center',
          paddingBottom: 5,

          fontWeight: 'bold',
          color: palette.darkBlue,
          fontSize: window.innerWidth < 600 ? '1.0rem' : '1.5rem',
        }}
      >
        {t('laundryRules.description')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: 2,
          justifyContent: 'left',
        }}
      >
        {RULES.map((rule, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              paddingBottom: 3,
            }}
          >
            <ErrorOutlineIcon sx={{ color: 'red', marginRight: 3 }} />
            <Typography
              variant="body1"
              sx={{
                fontWeight: 'bold',
                color: palette.darkBlue,
                fontSize: window.innerWidth < 600 ? '1.0rem' : '1.5rem',
              }}
            >
              {rule}
            </Typography>
          </Box>
        ))}{' '}
      </Box>
    </>
  );
};

const RecommendationsPage = () => {
  const { t } = useTranslation();
  useScrollToTop();
  return (
    <>
      <RecommendationsTitle>{t('navbar.recommendations')}</RecommendationsTitle>
      <RecommendationsImg />
      <Box
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          marginTop: '50px',
          borderRadius: '48px',
          paddingBottom: 12,
        }}
      >
        <LaundyRulesTitle />
        <LaundryRulesContent />
      </Box>
    </>
  );
};

export default RecommendationsPage;
