import { ReactNode } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import { CardMedia } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { palette } from '../utils/styleguide';
import { ImgsServices } from '../utils/utils';
import { t } from 'i18next';
import { ServiceCardProps } from '../utils/types';
import { useCloverNavigator } from '../utils/hook';

const ImgContainer = ({ imageSrc }: { imageSrc: string }) => {
  return (
    <Grid>
      <CardMedia
        component="img"
        src={imageSrc}
        sx={{
          height: 200,
          objectFit: 'contain',
          marginTop: 2,
          marginBottom: 2,
        }}
      />
    </Grid>
  );
};

const ServicesWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Grid
      container
      justifyContent="center"
      paddingTop={2}
      paddingBottom={10}
      spacing={4}
      direction="row"
    >
      {children}
    </Grid>
  );
};

const ServicesAndPricesSectionWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{
        backgroundColor: palette.darkWhite,
        marginTop: '-112px',
        paddingTop: {
          xs: 6,
          sm: 8,
          md: 15,
          lg: 15,
          xl: 15,
        },
      }}
    >
      <Grid>
        <Typography
          id={'services'}
          variant="h4"
          sx={{
            textAlign: 'center',
            paddingBottom: 10,
            fontWeight: 'bold',
            color: palette.darkBlue,
            fontSize: window.innerWidth < 600 ? '2.0rem' : '2.5rem',
          }}
        >
          {t('mainTitleServices')}
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent="center"
        paddingTop={2}
        paddingBottom={10}
        spacing={4}
        direction="row"
      >
        {children}
      </Grid>
    </Grid>
  );
};

const ServiceCard = ({
  title,
  description,
  imageSrc,
  navigationCallback,
  pageIndex,
}: ServiceCardProps) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Card
        sx={{
          maxWidth: 300,
          width: '100%',
          height: '100%',
          borderRadius: '20px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          flexDirection: 'column',
          padding: 2,
          cursor: 'pointer',
        }}
        onClick={() => navigationCallback(pageIndex)}
      >
        <ImgContainer imageSrc={imageSrc} />

        <CardContent sx={{ flexGrow: 1 }}>
          <Grid container direction="column" spacing={2} alignItems="center">
            <Grid>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: palette.darkBlue,
                }}
              >
                {title}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

const ServicesSection = () => {
  const { t } = useTranslation();

  const { navigateToService } = useCloverNavigator();

  const ALL_SERVICES_TRANSLATIONS: ServiceCardProps[] = t('services', {
    returnObjects: true,
  }) as ServiceCardProps[];

  const serivesWithoutOne = ALL_SERVICES_TRANSLATIONS.filter(
    (service) => service.title !== 'servicio de limpieza de apartamentos'
  );

  return (
    <>
      <ServicesAndPricesSectionWrapper>
        <ServicesWrapper>
          {serivesWithoutOne.map(
            ({ title, description, additionalData }, index: number) => (
              <ServiceCard
                key={index}
                title={title}
                pageIndex={index}
                description={description}
                additionalData={additionalData}
                imageSrc={ImgsServices[index]}
                navigationCallback={navigateToService}
              />
            )
          )}
        </ServicesWrapper>
      </ServicesAndPricesSectionWrapper>
    </>
  );
};

export default ServicesSection;
