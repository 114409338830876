import { useTranslation } from 'react-i18next';
import { ServicesAdditionalData } from '../../utils/types';
import { ImgsFullService, SERVICE_INDEX } from '../../utils/utils';
import { useScrollToTop } from '../../utils/hook';
import {
  ContentCardServices,
  Separator,
  TitleContainer,
} from '../CommonComponents';

const FullServiceSection = () => {
  const { t } = useTranslation();

  useScrollToTop();

  const FULL_SERVICE_ADDITIONAL_DATA = t(
    'services.' + SERVICE_INDEX.FULL_SERVICE + '.additionalData',
    {
      returnObjects: true,
    }
  ) as ServicesAdditionalData[];

  return (
    <>
      <Separator />
      <TitleContainer serviceIndex={SERVICE_INDEX.FULL_SERVICE} />
      <ContentCardServices
        imgs={ImgsFullService}
        additionalData={FULL_SERVICE_ADDITIONAL_DATA}
      />
    </>
  );
};

export default FullServiceSection;
