import Navbar from './components/navbar/Navbar';
import { ThemeProvider } from '@emotion/react';
import { AppWrapper, theme } from './utils/styleguide';
import ScrollToTopButton from './components/ScrollButton';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './translation/translation-en.json';
import esTranslation from './translation/translation-es.json';
import ContactSection from './sections/ContactSection';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ServicesPage from './pages/services/ServicesPage';
import LuggagePage from './pages/services/LuggagePage';
import { CloverNavbarLogo } from './components/CloverNavbarLogo';
import ComercialLaundryPage from './pages/services/ComercialLaundryPage';
import LandingPage from './pages/LandingPage';
import ErrorPage from './pages/ErrorPage';
import ThankYouPage from './pages/ThankYouPage';
import CookiePolicyPage from './pages/CookiePolicyPage';
import CookiesModal from './components/CookiesModal';
import RecommendationsPage from './pages/RecommendationsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicy';
import { useInitLanguage } from './utils/hook';

i18n.use(initReactI18next).init({
  resources: {
    es: { translation: esTranslation },
    en: { translation: enTranslation },
  },
  lng: 'es',
  fallbackLng: 'es',
  interpolation: {
    escapeValue: false,
  },
});

export const CloverApp = () => {
  useInitLanguage();

  return (
    <BrowserRouter basename={'/'}>
      <ThemeProvider theme={theme}>
        <AppWrapper>
          <Navbar />
          <CloverNavbarLogo />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/services/:serviceId" element={<ServicesPage />} />
            <Route path="/luggage" element={<LuggagePage />} />
            <Route path="/comercial" element={<ComercialLaundryPage />} />
            <Route path="/thank-you" element={<ThankYouPage />} />
            <Route path="*" element={<ErrorPage />} />
            <Route path="/cookie-policy" element={<CookiePolicyPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/recommendations" element={<RecommendationsPage />} />
          </Routes>
          {<CookiesModal />}
          <ContactSection />
          <ScrollToTopButton />
        </AppWrapper>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default CloverApp;
