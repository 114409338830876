import { useMediaQuery } from '@mui/material';
import { theme } from './styleguide';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { HomePageScrollSections, ParamServiceId } from './types';
import {
  getOffsetBySectionId,
  handleScrollTo,
  SERVICE_INDEX,
  ZERO,
} from './utils';
import i18n from 'i18next';

export const useViewPort = () => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const dynamicHeight = () => {
    switch (true) {
      case isDesktop:
        return '700px';
      case isTablet:
        return '500px';
      case isMobile:
        return '300px';
      default:
        return '800px';
    }
  };

  const dynamicPadding = () => {
    switch (true) {
      case isDesktop:
        return 40;
      case isTablet:
        return 30;
      case isMobile:
        return 10;
      default:
        return 40;
    }
  };

  return { isDesktop, isTablet, isMobile, dynamicHeight, dynamicPadding };
};

export const useViewportSize = () => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return size;
};

export const useCloverNavigator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { serviceId } = useParams<{ serviceId: ParamServiceId }>();

  const navigateHomePage = (withScrollableSection?: HomePageScrollSections) => {
    navigate('/');
    if (!withScrollableSection) return;
    setTimeout(() => {
      const targetOffset = getOffsetBySectionId(withScrollableSection);
      handleScrollTo(targetOffset);
    }, 300);
  };

  const navigateFullService = () => {
    navigate('/services/full-service');
  };
  const navigateLuggage = () => {
    navigate('/services/luggage');
  };
  const navigateApartmentCleaning = () => {
    navigate('/services/apartment-cleaning');
  };
  const navigateCommercialLaundry = () => {
    navigate('/services/commercial-laundry');
  };
  const navigateCookiesPolicy = () => {
    navigate('/cookie-policy');
  };
  const navigateRecommendations = () => {
    navigate('/recommendations');
    if (location.pathname === '/recommendations') {
      handleScrollTo(ZERO);
    }
  };

  const isHomePage = location.pathname === '/';

  const isFullService = serviceId === 'full-service';
  const isLuggage = serviceId === 'luggage';
  const isApartmentCleaning = serviceId === 'apartment-cleaning';
  const isCommercialLaundry = serviceId === 'commercial-laundry';

  const navigateToService = (serviceIndex: SERVICE_INDEX) => {
    switch (serviceIndex) {
      case SERVICE_INDEX.FULL_SERVICE:
        navigateFullService();
        break;
      case SERVICE_INDEX.LUGGAGE:
        navigateLuggage();
        break;
      case SERVICE_INDEX.APT_CLEANING:
        navigateApartmentCleaning();
        break;
      case SERVICE_INDEX.COMMERCIAL:
        navigateCommercialLaundry();
        break;
      default:
        break;
    }
  };

  return {
    isHomePage,
    navigateHomePage,
    isFullService,
    navigateFullService,
    isLuggage,
    navigateLuggage,
    isApartmentCleaning,
    navigateApartmentCleaning,
    isCommercialLaundry,
    navigateCommercialLaundry,
    navigateToService,
    navigateCookiesPolicy,
    navigateRecommendations,
  };
};

export const useScrollToTop = () => {
  useEffect(() => {
    handleScrollTo(ZERO);
  }, []);
};

export const useInitLanguage = () => {
  useEffect(() => {
    const savedLanguage = localStorage.getItem('i18nextLng') || 'es';
    i18n.changeLanguage(savedLanguage);
  }, []);
  return;
};
