import { Button, Grid2 as Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { palette } from '../utils/styleguide';
import { useTranslation } from 'react-i18next';
import { SERVICE_INDEX } from '../utils/utils';

const StyledFormContainer = styled(Grid)`
  padding-bottom: 2rem;

  .form-group {
    gap: 1rem;
  }

  .form-control {
    min-width: 300px;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    gap: 1rem;
  }

  input,
  textarea {
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
`;

const FORM_SUBMIT_ADDRESS = 'afd98d5fd65c2327d93376e7b45997da';

const ContactForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        justifyContent="center"
        sx={{
          backgroundColor: palette.darkWhite,
          width: '100%',
          paddingLeft: { xs: 2, sm: 5, md: 10, lg: 30, xl: 55 },
          paddingRight: { xs: 2, sm: 5, md: 10, lg: 30, xl: 55 },
          paddingBottom: { xs: 2, sm: 3, md: 4, lg: 5 },
        }}
      >
        <Typography
          variant={'h4'}
          align="center"
          sx={{
            color: palette.darkBlue,
            fontSize: { xs: '1.8rem', sm: '2.3rem' },
          }}
        >
          {window.location.pathname.includes('commercial-laundry')
            ? t(`services.${SERVICE_INDEX.COMMERCIAL}.contactUsCommercial`)
            : window.location.pathname.includes('apartment-cleaning')
            ? t(
                `services.${SERVICE_INDEX.APT_CLEANING}.contactUsApartmentService`
              )
            : t('booking.contactUs')}
        </Typography>
      </Grid>
      <StyledFormContainer
        container
        flexDirection={'column'}
        sx={{
          gap: 2,
          justifyContent: 'center',
          alignContent: 'center',
          backgroundColor: palette.darkWhite,
          width: '100%',
        }}
      >
        <form
          target="_self"
          action={'https://formsubmit.co/' + FORM_SUBMIT_ADDRESS}
          method="POST"
        >
          <input
            type="hidden"
            name="_next"
            value="https://cloverlavanderia.es/thank-you" //cloverlavanderia.es/thank-you
          />

          <input
            type="text"
            name="name"
            className="form-control"
            placeholder={t('booking.form.name')}
            required
            autoComplete="off"
          />

          <input
            type="email"
            name="email"
            className="form-control"
            placeholder={t('booking.form.email')}
            required
            autoComplete="off"
          />
          <input
            type="phone"
            name="phone"
            className="form-control"
            placeholder={t('booking.form.phone')}
            required
            autoComplete="off"
          />

          <textarea
            placeholder={t('booking.form.message')}
            className="form-control"
            name="message"
            rows={8}
            required
            style={{ resize: 'none' }}
            autoComplete="off"
          ></textarea>

          <Button type="submit" variant={'contained'}>
            {t('booking.send')}
          </Button>
        </form>
      </StyledFormContainer>
    </>
  );
};

export default ContactForm;
