import { Typography } from '@mui/material';
import { ParallaxLogo, ParallaxSection } from '../styles/styles';
import { palette } from '../utils/styleguide';
import { useEffect, useState } from 'react';
import { useCloverNavigator, useViewportSize } from '../utils/hook';
import { handleScrollTo, ZERO } from '../utils/utils';

export const Parallax = () => {
  const [isSticky, setIsSticky] = useState(false);
  const { width } = useViewportSize();
  const { navigateHomePage } = useCloverNavigator();

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const logoSize = width > 1200 ? '500px' : width > 768 ? '400px' : '300px';

  return (
    <ParallaxSection>
      <Typography variant="h1" fontWeight={800} sx={{ color: palette.white }}>
        <ParallaxLogo
          key={'logoClover' + width}
          src="./logoClover.svg"
          alt="Clover"
          className={isSticky ? 'sticky' : ''}
          width={logoSize}
          height={logoSize}
          onClick={() => {
            handleScrollTo(ZERO);
            navigateHomePage();
          }}
        />
      </Typography>
    </ParallaxSection>
  );
};
