import Grid from '@mui/material/Grid2';
import { ServicesAdditionalData } from '../utils/types';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { palette } from '../utils/styleguide';
import { Fragment } from 'react';
import { t } from 'i18next';

export const ContentCardServices = ({
  additionalData,
  imgs,
}: {
  additionalData: ServicesAdditionalData[];
  imgs: string[];
}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent={'center'}
      flexWrap={'wrap'}
      spacing={3}
      marginBottom={10}
      marginRight={{ xs: 5, sm: 0 }}
      marginLeft={{ xs: 5, sm: 0 }}
    >
      {additionalData.map((item, index: number) => (
        <Card
          key={index}
          sx={{
            width: { xs: '100%', sm: 600 },
            height: { xs: 'auto', sm: 400 },
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            borderRadius: '20px',
            backgroundColor: palette.darkWhite,
            boxShadow: 3,
            overflow: 'hidden',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: { xs: '70%', sm: '80%' },
              maxWidth: 200,
              height: 'auto',
              objectFit: 'contain',
              padding: 5, //
            }}
            image={imgs[index]}
            alt="laundry service"
          />

          <CardContent
            sx={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: { xs: 1, sm: 2 },
            }}
          >
            <Typography
              sx={{
                color: palette.darkBlue,
                fontSize: { xs: '1.6rem', sm: '1.6rem' },
                fontWeight: 'bold',
                marginBottom: 1,
              }}
              variant="h5"
              component="div"
            >
              {item.title}
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: palette.darkBlue, marginBottom: 1 }}
            >
              {item.price &&
                item.price.split(')').map((part, index, array) => (
                  <Fragment key={index}>
                    {part.trim()}
                    {index < array.length - 1 && ')'}
                    {index < array.length - 1 && <br />}
                  </Fragment>
                ))}
            </Typography>

            <Typography sx={{ color: palette.darkBlue, marginBottom: 1 }}>
              {item.descriptionPrice}
            </Typography>
            <Typography sx={{ color: palette.darkBlue }}>
              {item.price && <strong>{item.schedule}</strong>}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Grid>
  );
};

export const Separator = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '80px',
      }}
    />
  );
};

export const TitleContainer = ({ serviceIndex }: { serviceIndex: number }) => {
  return (
    <Typography
      id={'services'}
      variant="h4"
      sx={{
        textAlign: 'center',
        paddingBottom: 9,
        paddingTop: 20,
        fontWeight: 'bold',
        color: palette.darkBlue,
        fontSize: window.innerWidth < 600 ? '2.0rem' : '2.5rem',
      }}
    >
      {t('services.' + serviceIndex + '.title')}
    </Typography>
  );
};
