import { PAGES_NAMES, SERVICE_INDEX } from '../../utils/utils';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { formattedId } from '../../utils/utils';
import { palette } from '../../utils/styleguide';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCloverNavigator } from '../../utils/hook';
import { SupportedLanguages } from '../../utils/types';

type DesktopMenuProps = {
  handleScroll: (sectionId: string) => void;
  handleLanguage: (lng: SupportedLanguages) => void;
  selectedLanguage: SupportedLanguages;
};

export const DesktopMenu = ({
  handleScroll,
  handleLanguage,
  selectedLanguage,
}: DesktopMenuProps) => {
  const { t } = useTranslation();
  const [anchorServices, setAnchorServices] = useState<null | HTMLElement>(
    null
  );
  const open = Boolean(anchorServices);
  const {
    isHomePage,
    navigateHomePage,
    navigateToService,
    navigateRecommendations,
  } = useCloverNavigator();

  const onNavItemHandler = (page: string) => {
    if (['faq'].includes(page) && !isHomePage) {
      navigateHomePage(page as 'faq');
    }
    if (page === 'recommendations') {
      navigateRecommendations();
      return;
    }
    handleScroll(formattedId(page));
  };

  const handleServicesClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isHomePage) navigateHomePage('services');
    setAnchorServices(event.currentTarget);
  };

  const handleServicesClose = () => {
    setAnchorServices(null);
  };

  return (
    <>
      {PAGES_NAMES.map((page, index) => {
        const translatedPage = t(`navbar.${formattedId(page)}`);
        const isLeftAligned = index == 1; // First 2 pages on the left
        return (
          <Box
            key={page}
            sx={{
              ...(isLeftAligned && { marginRight: 'auto' }),
            }}
          >
            <Button
              onClick={() => onNavItemHandler(page)}
              sx={{
                my: 2,
                color: palette.darkBlue,
                display: 'block',
                fontWeight: 'bold',
                marginRight: 2,
              }}
              {...(page === 'services' && {
                'aria-controls': 'services-menu',
                'aria-haspopup': 'true',
                onClick: handleServicesClick,
              })}
            >
              {translatedPage}
            </Button>

            {page === 'services' && open && anchorServices && (
              <Menu
                id="services-menu"
                anchorEl={anchorServices}
                open={open}
                onClose={handleServicesClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                MenuListProps={{
                  'aria-labelledby': 'services-button',
                }}
                disableScrollLock={true}
              >
                <MenuItem
                  onClick={() => {
                    navigateToService(SERVICE_INDEX.FULL_SERVICE);
                    handleServicesClose();
                  }}
                >
                  {t('navbar.fullAndSelfService')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigateToService(SERVICE_INDEX.LUGGAGE);
                    handleServicesClose();
                  }}
                >
                  {t('navbar.luggageStorageService')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigateToService(SERVICE_INDEX.APT_CLEANING);
                    handleServicesClose();
                  }}
                >
                  {t('navbar.apartmentCleaningService')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigateToService(SERVICE_INDEX.COMMERCIAL);
                    handleServicesClose();
                  }}
                >
                  {t('navbar.commercialLaundryService')}
                </MenuItem>
              </Menu>
            )}
          </Box>
        );
      })}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/ukFlag.svg`}
          alt="UK Flag"
          width="30"
          height="30"
          onClick={() => handleLanguage('en')}
          style={{
            cursor: 'pointer',
            marginRight: '10px',
            opacity: selectedLanguage === 'es' ? 0.3 : 1,
          }}
        />
        <img
          src={`${process.env.PUBLIC_URL}/spainFlag.svg`}
          alt="Spain Flag"
          width="30"
          height="30"
          onClick={() => handleLanguage('es')}
          style={{
            cursor: 'pointer',
            marginRight: '10px',
            opacity: selectedLanguage === 'en' ? 0.3 : 1,
          }}
        />
      </Box>
    </>
  );
};
